import type { IMenuElementData, IMenuElementItem } from '@app/features/menu/interface/IMenu';

import { Component } from '@angular/core';

import { getStyles } from '@app/features/menu/components/menu-view/menu-view.component.styles';
import { EMenuType } from '@app/features/menu/enums/menu.enum';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-menu-feature',
    templateUrl: './menu-feature.component.html',
    styleUrls: ['./menu-feature.component.less'],
})
export default class MenuFeatureComponent extends BaseFeatureComponent<IMenuElementData> {
    public isOpen = false;
    protected getStyles = getStyles;

    public get menuList(): IMenuElementItem[] {
        return this.elementData.list;
    }

    public get imgSrc(): string {
        return this.formatImgSrc('./assets/img/lpc-i-menu-icon.svg');
    }

    private getNormalizedLink(type: EMenuType, link: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        if (type === EMenuType.email) {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === EMenuType.phone) {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public getMenuHref(item: IMenuElementItem): string {
        const { value, type } = item;
        const normalizedLink = this.getNormalizedLink(type, value);

        if (!normalizedLink) {
            return null;
        }

        return normalizedLink;
    }

    public getTarget(item: IMenuElementItem): string {
        return item.targetBlank ? '_blank' : '_self';
    }

    public open(): void {
        this.isOpen = true;
    }
    public close(): void {
        this.isOpen = false;
    }
}
