<div [class]="classes?.element">
    <div *ngFor="let accordionItem of accordionList; let i = index">
        <div [class]="classes?.wrapAccordionItem">
            <div class="accordionItem" [ngClass]="accordionItem.isOpen ? 'open-acc-item' : 'close-acc-item'">
                <div [class]="classes?.wrapHeader" class="wrapHeader" (click)="toggle(i)">
                    <div [class]="classes?.header" [innerHTML]="accordionItem.header | spSafeHtml"></div>
                    <div *ngIf="elementData.iconType === EAccordionIconType.caret" [class]="classes?.wrapIcon">
                        <div *ngIf="accordionItem.isOpen" [innerHTML]="getIcon('caretUp')" class="icon"></div>
                        <div *ngIf="!accordionItem.isOpen" [innerHTML]="getIcon('caretDown')" class="icon"></div>
                    </div>
                    <div *ngIf="elementData.iconType === EAccordionIconType.plusMinus" [class]="classes?.wrapIcon">
                        <div *ngIf="accordionItem.isOpen" [innerHTML]="getIcon('minus')" class="icon"></div>
                        <div *ngIf="!accordionItem.isOpen" [innerHTML]="getIcon('plus')" class="icon"></div>
                    </div>
                </div>

                <div class="body" [class]="classes?.body" [innerHTML]="accordionItem.body | spSafeHtml"></div>
            </div>
        </div>
    </div>
</div>
