<div class="element-view-modal" [class]="classes.modal">
    <div [class]="classes.modalHeader">
        <h2>{{ 'order_form' | translate }}</h2>
        <button type="button" (click)="onCancel()">
            <span>&times;</span>
        </button>
    </div>
    <div [class]="classes.modalBody">
        <p>{{ elementData.productName }}</p>

        <span class="price-value" *ngIf="selectedElement && !selectedElement.isEditableTotalCost">
            {{ selectedElement.amount }} {{ selectedElement.currency }}
        </span>

        <div *ngIf="selectedElement && selectedElement.isEditableTotalCost" [class]="classes?.spField" class="min-amount-control">
            <label>{{ "amount" | translate }}</label>
            <div class="input-group">
                <input
                    [class]="classes?.spFormControl"
                    [value]="totalCost.value"
                    [min]="selectedElement.amount"
                    type="number"
                    (change)="changeAmount($event)"
                    (blur)="validateAmount()"
                />

                <div class="input-group-addon">
                    {{ selectedElement.currency }}
                </div>
            </div>

            <div class="sp-ui-text">
                <p>
                    <small>{{ "minimal_amount" | translate:{ PRICE: selectedElement.amount, CURRENCY: selectedElement.currency } }}</small>
                </p>
            </div>
        </div>

        <span class="modal-divider"></span>

        <div class="form-content">
            <div [id]="element.id" [class]="classes?.form" [formGroup]="elementsFormGroup">
                <form method="POST" [action]="action" ngNoForm #form formArrayName="elementsFormArray">
                    <div *ngFor="let control of elementsFormArray.controls; index as i" [ngSwitch]="true"
                         [class]="classes.spField" [class.has-error]="(control.invalid || (control.value.type === 'select' && control.value.required && !control.value.value)) && submitted" [formGroupName]="i">
                        <ng-container *ngSwitchCase="typeGuards.isInput(control.value)" >
                            <label [class.hidden]="control.value.type === formInputType.hidden" *ngIf="elementData.showLabels">{{ control.value.label }}{{ control.value.required ? ' *' : '' }}</label>
                            <ng-container *ngIf="!control.value.isDynamicPlaceholderEnabled">
                                <input
                                    [id]="getInputIdForSelenium(control)"
                                    [class]="classes.spFormControl"
                                    [attr.type]="control.value.htmlInputType"
                                    [attr.autocomplete]="control.value.autocompleteType"
                                    [attr.name]="getControlNameAttr(control)"
                                    [attr.placeholder]="control.value.placeholder"
                                    [required]="control.value.required"
                                    formControlName="value"
                                    [value]="control.value.type === formInputType.hidden ? control.value.value : ''">
                            </ng-container>
                            <ng-container *ngIf="control.value.type === 'phone' && control.value.isDynamicPlaceholderEnabled">
                                <ngx-intl-tel-input
                                    [cssClass]="classes?.spFormControl"
                                    [class]="'intl-size-' + elementData.fieldsSize"
                                    [enableAutoCountrySelect]="true"
                                    [selectFirstCountry]="false"
                                    [separateDialCode]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [phoneValidation]="true"
                                    [searchCountryField]="[SearchCountryField.All]"
                                    [excludeCountries]="excludeCountries"
                                    [selectedCountryISO]="CountryISO"
                                    [numberFormat]="PhoneNumberFormat.International">
                                </ngx-intl-tel-input>
                                <div class="text-error-msg" *ngIf="isDynamicPhoneError">
                                    {{ 'control_errors_phone_format' | translate: { PHONE_FORMAT: getPhoneErrorMsg() } }}
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="typeGuards.isTextarea(control.value)">
                            <label *ngIf="elementData.showLabels"> {{ control.value.label }}{{ control.value.required ? " *" : "" }} </label>
                            <textarea
                                [attr.autocomplete]="control.value.autocompleteType"
                                [attr.name]="getControlNameAttr(control)"
                                [required]="control.value.required"
                                [maxLength]="5000"
                                [class]="classes?.spFormCotrolTextarea"
                                formControlName="value"
                                [value]="control.value.value"
                                [attr.placeholder]="control.value.placeholder"></textarea>
                        </ng-container>

                        <ng-container *ngSwitchCase="typeGuards.isCheckbox(control.value)">
                            <label class="sp-checkbox">
                                <input
                                    [id]="getInputIdForSelenium(control)"
                                    [class]="classes.spCheckbox"
                                    [attr.type]="control.value.htmlInputType"
                                    [attr.name]="getControlNameAttr(control)"
                                    [required]="control.value.required"
                                    (change)="change(control, $event)">
                                <span *ngIf="control.value.required" class="checkbox-text" [innerHTML]="' ' + (control.value.label | removePTags) + ' *'"></span>
                                <span *ngIf="!control.value.required" class="checkbox-text" [innerHTML]="' ' + (control.value.label | removePTags)"></span>
                                <!-- <span> {{ control.value.label }}{{ control.value.required ? ' *' : '' }}</span> -->
                            </label>
                        </ng-container>

                        <ng-container *ngSwitchCase="typeGuards.isRadio(control.value)">
                            <label *ngIf="elementData.showLabels">{{ control.value.label }}</label>
                            <div class="radio-options" *ngFor="let option of control.value.options; index as optsIndex">
                                <label class="sp-radio">
                                    <input
                                        [id]="getInputIdForSelenium(control)"
                                        [class]="classes.spRadio"
                                        [attr.type]="control.value.htmlInputType"
                                        [attr.name]="'control-' + i"
                                        [value]="option.value"
                                        [checked]="option.selected"
                                        [required]="control.value.required"
                                        (change)="changeRadio(control, optsIndex)">
                                    <span> {{ option.label }}</span>
                                </label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="typeGuards.isSelect(control.value)">
                            <label *ngIf="elementData.showLabels">{{ control.value.label }}{{ control.value.required ? " *" : "" }}</label>
                            <label class="sp-select">
                                <select
                                    [id]="getInputIdForSelenium(control)"
                                    [class]="classes.spSelect"
                                    (change)="changeSelectValue(control, $event)">
                                    <option [ngValue]="null" [selected]="getSelected(control.value.options)" disabled>
                                        {{ "select_value" | translate }}
                                    </option>
                                    <option
                                        *ngFor="let option of control.value.options"
                                        [class]="classes.spSelect"
                                        [attr.type]="control.value.htmlInputType"
                                        [value]="option.value"
                                        [selected]="option.selected"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </label>
                        </ng-container>

                    </div>
                </form>
            </div>
        </div>

        <a
            [class.disabled]="!validPayments.length"
            [class]="classes?.element"
            [id]="element.id + '-submit'"
            class="btn-hover"
            (click)="submitForm()">
            <div [class]="classes?.wrapContent">
                <span [class]="classes?.label">{{ elementData.buttonText }}</span>
                <span
                    *ngIf="!!elementData.buttonDescription"
                    [class]="classes?.description">
                        {{ elementData.buttonDescription }}
                    </span>
            </div>
        </a>

        <div class="alert alert-info-mutted alert-sm" *ngIf="!validPayments.length">
            {{ 'payment_is_temporarily_unavailable' | translate }}
        </div>

        <div *ngIf="isShowInvalidPaymentMethodError" class="text-error-msg">
            {{ "invalid_payment_method_error" | translate }}
        </div>
    </div>
</div>
