import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type SafeHtml } from '@angular/platform-browser';

import { getStyles } from '@app/features/social-networks/components/social-networks-view/social-networks-view.component.styles';
import { socialNetworksBrandColors, socialNetworksTextColors } from '@app/features/social-networks/consts/social-networks.const';
import { type ISocialNetworksElementData } from '@app/features/social-networks/interface/ISocialNetworksElement';
import { EButtonStyleType, EButtonType, ESocialNetworks } from '@common/enums';
import { socialIconTemplates } from '@app/shared/templates/svg/social-icons';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-social-networks-feature',
    templateUrl: './social-networks-feature.component.html',
    styleUrls: ['./social-networks-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class SocialNetworksFeatureComponent extends BaseFeatureComponent<ISocialNetworksElementData> {
    protected getStyles = getStyles;

    public getBrandColors(name: string): string {
        return socialNetworksBrandColors[name];
    }

    public chatTextColors(name: string): string {
        return socialNetworksTextColors[name];
    }

    public get isButtonTypeClassic(): boolean {
        return this.elementData.buttonType === EButtonStyleType.classic;
    }

    public get isSocialNetworkTypeIcon(): boolean {
        return this.elementData.type === EButtonType.icon;
    }

    public getIcon(iconName): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(socialIconTemplates[iconName]);
    }

    public getIconSrc(iconName): string {
        return this.formatImgSrc(`./assets/img/social-icons/${this.element.data.iconType}/${iconName}-${this.element.data.iconType}.svg`);
    }

    public getSocialType(social): string {
        switch (social.name) {
            case ESocialNetworks.email:
                return 'email';
            case ESocialNetworks.phone:
                return 'phone';
            default:
                return 'social';
        }
    }

    private getNormalizedLink(link: string, type: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        if (type === 'email') {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === 'phone') {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public getSocialLink(social): string {
        const socialType = this.getSocialType(social);
        const normalizedLink = this.getNormalizedLink(social.link, socialType);

        if (!normalizedLink) {
            return null;
        }

        return normalizedLink;
    }
}
