import type { SafeHtml } from '@angular/platform-browser';
import type { IChatBotsElementData, IChatBotElementData } from '@app/features/chat-bots/interface/IChatBotsElement';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { getStyles } from '@app/features/chat-bots/components/chat-bots-view/chat-bots-view.component.styles';
import { chatBotsBrandColors, chatBotsTextColors } from '@app/features/chat-bots/consts/chat-bots.consts';
import { EButtonStyleType, EButtonType, ESocialNetworks } from '@common/enums';
import { socialIconTemplates } from '@app/shared/templates/svg/social-icons';
import { EChatBotsMessengers } from '@app/features/chat-bots/enum/chat-bots.enum';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-chat-bots-feature',
    templateUrl: './chat-bots-feature.component.html',
    styleUrls: ['./chat-bots-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class ChatBotsFeatureComponent extends BaseFeatureComponent<IChatBotsElementData> {
    protected getStyles = getStyles;

    public getBrandColors(name: string): string {
        return chatBotsBrandColors[name];
    }

    public chatBotsTextColors(name: string): string {
        return chatBotsTextColors[name];
    }

    public getChatBotType(chatBot: IChatBotElementData): 'email' | 'phone' | 'chatBot' {
        switch (chatBot.messenger) {
            case EChatBotsMessengers.email:
                return 'email';
            case EChatBotsMessengers.phone:
                return 'phone';
            default:
                return 'chatBot';
        }
    }

    private getNormalizedLink(link: string, type: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return '';
        }

        if (type === 'email') {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === 'phone') {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    private getChatBotURL(chatBot: IChatBotElementData): { url: string; searchParams?: URLSearchParams } {
        try {
            const url = new URL(chatBot.link);
            if (chatBot.messenger === EChatBotsMessengers.viber) {
                return {
                    url: url.href,
                };
            }

            const normalizedUrl = url.origin + url.pathname;

            if (chatBot.messenger === EChatBotsMessengers.telegram) {
                const telegramQueryParams = this.utmTagsService.parseTelegramQueryParams(url.search);

                if ((chatBot?.botChain as any)?.chainId) {
                    telegramQueryParams.set('start', (chatBot.botChain as any)?.chainId);
                    return {
                        url: `https://tg.pulse.is/${chatBot.botName}`,
                        searchParams: telegramQueryParams,
                    };
                }

                if (normalizedUrl.includes('t.me') && url.pathname.toLowerCase().includes('bot')) {
                    return {
                        url: normalizedUrl.replace('t.me', 'tg.pulse.is'),
                        searchParams: telegramQueryParams,
                    };
                }

                return {
                    url: normalizedUrl,
                    searchParams: telegramQueryParams,
                };
            }

            if (chatBot.messenger === ESocialNetworks.instagram) {
                const instagramQueryParams = this.utmTagsService.parseInstagramQueryParams(url.search);
                if ((chatBot?.botChain as any)?.chainId) {
                    instagramQueryParams.set('ref', (chatBot.botChain as any)?.chainId);
                }
                return {
                    url: normalizedUrl,
                    searchParams: instagramQueryParams,
                };
            }

            if (chatBot.messenger === ESocialNetworks.facebook) {
                const facebookQueryParams = this.utmTagsService.parseFacebookQueryParams(url.search);
                if ((chatBot?.botChain as any)?.chainId) {
                    facebookQueryParams.set('ref', (chatBot.botChain as any)?.chainId);
                }

                return {
                    url: normalizedUrl,
                    searchParams: facebookQueryParams,
                };
            }

            return {
                url: normalizedUrl,
                searchParams: url.searchParams,
            };
        } catch (e) {
            return {
                url: chatBot.link,
            };
        }
    }

    private processLink(chatBot: IChatBotElementData): string {
        const { url, searchParams } = this.getChatBotURL(chatBot);
        if (!url) {
            return '';
        }

        const utmTags = this.utmTagsService.getChatBotUtmTags({
            social: chatBot.messenger as any,
            chatBotSearchParams: searchParams,
        });
        if (!utmTags) {
            return url;
        }

        return url + utmTags;
    }

    public getChatBotLink(chatBot: IChatBotElementData): string {
        const chatBotType = this.getChatBotType(chatBot);
        if (chatBotType !== 'chatBot') {
            return this.getNormalizedLink(chatBot.link, chatBotType);
        }

        const normalizedLink = this.getNormalizedLink(this.processLink(chatBot), chatBotType);
        if (!normalizedLink) {
            return '';
        }

        return normalizedLink;
    }

    public getIcon(iconName: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(socialIconTemplates[iconName]);
    }

    public getIconSrc(iconName: string): string {
        return this.formatImgSrc(`./assets/img/social-icons/${this.element.data.iconType}/${iconName}-${this.element.data.iconType}.svg`);
    }

    public get isButtonTypeClassic(): boolean {
        return this.elementData.buttonType === EButtonStyleType.classic;
    }

    public get isChatBotsTypeIcon(): boolean {
        return this.elementData.type === EButtonType.icon;
    }
}
